<template>
  <div class="user-nickname-edit">
    <van-cell-group>
      <van-field v-model="nickname" placeholder="请输入昵称">
        <template #button>
          <div v-if="nickname" class="btn">
            <van-icon name="clear" color="#cccccc" size="20px" @click="inputClear" />
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <div class="footer">
      <van-button type="primary" size="normal" round block color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)" @click="onSubmit">完成</van-button>
    </div>
  </div>
</template>
<script>
import { changeNickname } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      nickname: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  mounted() {
    this.nickname = this.userInfos.nickname
  },
  methods: {
    inputClear() {
      this.nickname = ''
    },
    async onSubmit() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      await changeNickname({ nickname: this.nickname })
      loading.clear()
      this.$toast({
        message: '修改昵称成功',
        duration: 1000,
        onClose: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.user-nickname-edit {
  padding-top: 10px;
  .btn {
    display: flex;
    align-items: center;
  }
  .footer {
    padding: 16px;
  }
}
</style>
